/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import './src/styles/global.css'

export const onRouteUpdate = ({ location }) => {
  if (location.action === 'PUSH') {
    window.scrollTo(0, 0);
  }
};

// Single export for scroll behavior
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Return false to prevent scroll-to-top on same-page anchor links
  if (location.hash) {
    return false;
  }
  
  // Scroll to top for PUSH actions (new routes)
  if (location.action === 'PUSH') {
    return [0, 0];
  }

  // For POP actions (back/forward), restore the previous scroll position
  return getSavedScrollPosition(location) || true;
};